<template>
  <b-overlay
      :show="coupons === null"
      rounded="sm"
  >
    <div v-if="coupons !== null">

      <!-- Table Container Card -->
      <b-card
          no-body
          class="mb-0"
      >

        <b-table
            ref="refUserListTable"
            class="position-relative"
            :items="coupons"
            responsive
            :fields="myTableColumns"
            primary-key="id"
            show-empty
            bordered
            striped
            empty-text="موردی موجود نیست!"
        >

          <!-- Column: type -->

          <template #cell(type)="data">

            <div class=" d-flex flex-row ">
              <span v-if="data.item.type === 2" class="text-primary">مبلغ</span>
              <span v-else class="text-success">درصد</span>
            </div>

          </template>

          <!-- Column: couponValue -->

          <template #cell(couponValue)="data">

            <div class=" d-flex flex-row ">
              <span v-if="data.item.type === 2" class="text-primary">{{makePrettyPrice(data.item.couponValue)}} تومان</span>
              <span v-else class="text-success">{{data.item.couponValue}}%</span>
            </div>

          </template>

          <!-- Column: startDate -->

          <template #cell(startDate)="data">

            <small class="">
              {{data.item.startDate.slice(11,16)}}
              -
              {{createJalaliDate(data.item.startDate.slice(0,10))}}
            </small>

          </template>

          <!-- Column: endDate -->

          <template #cell(endDate)="data">

            <small class="">
              {{data.item.endDate.slice(11,16)}}
              -
              {{createJalaliDate(data.item.endDate.slice(0,10))}}
            </small>

          </template>

          <!-- Column: execution -->

          <template #cell(execution)="data">

            <div class=" d-flex flex-row ">
              <span v-if="data.item.execution === 1" class="text-primary">عمومی</span>
              <span v-else class="text-success">شخصی</span>
            </div>

          </template>

          <!-- Column: baseQuantity -->

          <template #cell(baseQuantity)="data">

            <div class=" d-flex flex-row ">
              <span v-if="data.item.execution === 2" class="text-success">-</span>
              <span v-else class="text-success">{{data.item.baseQuantity}}</span>
            </div>

          </template>

          <!-- Column: maintainedQuantity -->

          <template #cell(maintainedQuantity)="data">

            <div class=" d-flex flex-row ">
              <span v-if="data.item.execution === 2" class="text-success">-</span>
              <span v-else class="text-success">{{data.item.maintainedQuantity}}</span>
            </div>

          </template>

          <!-- Column: userId -->

          <template #cell(userId)="data">

            <div class=" d-flex flex-row ">
              <span v-if="data.item.execution === 1" class="text-success">-</span>
              <span v-else class="text-success">{{data.item.userId}}</span>
            </div>

          </template>

          <!-- Column: hasUsedByUser -->

          <template #cell(hasUsedByUser)="data">

            <div class=" d-flex flex-row ">
              <span v-if="data.item.hasUsedByUser" class="text-primary">استفاده شده</span>
              <span v-else class="text-danger">استفاده نشده</span>
            </div>

          </template>

          <!-- Column: createDate -->

          <template #cell(createDate)="data">

            <small class="">
              {{data.item.createDate.slice(11,16)}}
              -
              {{createJalaliDate(data.item.createDate.slice(0,10))}}
            </small>

          </template>

          <!-- Column: delete -->

          <template #cell(delete)="data">

            <div class="cursor-pointer d-flex flex-row"
                 v-b-modal.modal-delete
                 @click="showModal(data.item.couponId)"
            >
              <feather-icon icon="TrashIcon" size="20" class="text-danger" />
            </div>

          </template>

          <template #cell(edit)="data">

            <router-link :to="{ name: 'apps-coupon-edit', params: { id: data.item.couponId } }" class="cursor-pointer d-flex flex-row">
              <feather-icon icon="EditIcon" size="20"/>
            </router-link>

          </template>

          <template #cell(used)="data">

            <router-link :to="{ name: 'apps-coupon-used', params: { id: data.item.couponId } }" class="cursor-pointer d-flex flex-row">
              <feather-icon icon="UserIcon" size="20"/>
            </router-link>

          </template>

        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <!--            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>-->
            </b-col>
            <!-- Pagination -->
            <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                  v-model="currentPage"
                  :total-rows="totalCount"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon style="transform: rotate(180deg)"
                                icon="ChevronLeftIcon"
                                size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>
    </div>
    <b-modal
        id="modal-delete"
        centered
        ok-title="حذف"
        cancelTitle="انصراف"
        @ok="deleteCoupon(deleteItem)"
    >
      <span>حذف شود؟</span>
    </b-modal>
  </b-overlay>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,BOverlay,BModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {CouponGetAllRequest} from "@/libs/Api/Coupon";
import {CouponDeleteRequest} from "@/libs/Api/Coupon";
import Helper from "@/libs/Helper";


export default {
  name: "CouponList",
  title:"لیست کوپن ها - پنل ادمین مکس ",
  data(){
    return{
      coupons:null,
      totalCount:null,
      addNewCategory:false,
      currentPage:1,
      deleteItem:null,
      perPage:10,
      searchCommand:'',
      perPageOptions:[10, 20, 30 , 40 , 50],
      myTableColumns : [
        { key: 'couponId',label:'شناسه'},
        { key: 'type',label:'نوع'},
        { key: 'couponValue',label:'ارزش'},
        { key: 'startDate',label:'شروع'},
        { key: 'endDate',label:'پایان'},
        { key: 'productId',label:'محصول'},
        { key: 'execution',label:'نوع اجرا'},
        { key: 'baseQuantity',label:'تعداد کل'},
        { key: 'maintainedQuantity',label:'تعداد باقی مانده'},
        { key: 'userId',label:'شناسه شخص'},
        { key: 'code',label:'کد'},
        { key: 'hasUsedByUser',label:'وضعیت'},
        { key: 'createDate',label:'ثبت'},
        { key: 'delete',label:'حذف'},
        { key: 'edit',label:'ویرایش'},
        { key: 'used',label:'مصرف کنندگان'},
      ],
      defaultGetData:{
        pageNumber:1,
        count:10,
        searchCommand:''
      },
      defaultCreateData:{
        isDeleted:false,
        productCategoryId:0,
        parentId:0,
        name:''
      },
    }
  },
  async created(){
    await this.getAllCoupons(this.defaultGetData.pageNumber,this.defaultGetData.count)
  },

  methods:{
    async getAllCoupons(pageNumber,count){
      let  _this = this;
      let  defaultGetData={
        pageNumber:pageNumber,
        count:count,
      }

      let couponGetAllRequest = new CouponGetAllRequest(_this);
      couponGetAllRequest.setParams(defaultGetData);
      await couponGetAllRequest.fetch(function (content){
        _this.coupons = content.coupons;
        _this.totalCount = content.couponsCount;
      },function (error){
        console.log(error)
      })
    },
    async deleteCoupon(param){
      let _this = this;

      let couponDeleteRequest = new CouponDeleteRequest(_this);
      couponDeleteRequest.setId(param);
      await couponDeleteRequest.fetch(function (content){
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `کوپن حذف شد.`,
          },
        })
        _this.getAllCoupons(_this.defaultGetData.pageNumber,_this.defaultGetData.count)
      },function (error){
        console.log(error)
      })
    },
    createJalaliDate(param){
      return new Date(param).toLocaleDateString("fa-IR",);
    },
    makePrettyPrice(param){
      return Helper.prettyPrice(param,0)
    },
    showModal(param){
      let _this = this;
      _this.deleteItem = param
    },
  },
  watch:{
    currentPage:function (nv,ov) {
      this.getAllCoupons(nv,this.defaultGetData.count,this.defaultGetData.searchCommand);
    },
  },
  components:{
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    BModal,

    vSelect,
  },
}
</script>

<style scoped>

</style>